import React from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'

import Layout from '../components/layout'
import Seo from '../components/Seo'
import StructuredData from '../components/structured-data'
import { Link, graphql } from 'gatsby'
import parseHtml from '../components/blocks/parser'
import swapCmsUrlWithCdn from '../utils/swapCmsUrlWithCdn'

type ResourceProps = {
  location: Record<string, unknown>
  data: {
    resource: {
      title: string
      resourceFileUrl: string | null
      lwContent: {
        isGutenberg: boolean
        content?: string
      }
      date: string
      modified: string
      slug: string
      uri: string
      featuredImage: null | {
        node?: {
          mediaItemUrl: string
          altText: string
          publicURL: string
          localFile: { childImageSharp: { fluid: FluidObject } }
        }
      }
      seo: any
      categories: {
        nodes?: Array<{
          name: string
          id: string
          slug: string
        }>
      }
    }
  }
}

function Resource(props: ResourceProps): JSX.Element {
  const {
    data: { resource },
  } = props
  const { title, date, categories, lwContent, resourceFileUrl } = resource
  const category = categories?.nodes?.[0]
  const contentNode = lwContent.isGutenberg ? (
    <div className="entry blog-post">{parseHtml(lwContent.content)}</div>
  ) : (
    <div
      className="entry blog-post"
      dangerouslySetInnerHTML={{ __html: lwContent.content }}
    />
  )

  const featuredImage = swapCmsUrlWithCdn(
    resource.featuredImage?.node?.mediaItemUrl
  )

  return (
    <Layout>
      <Seo post={resource} />
      <StructuredData pageType="resource" post={resource} />
      <div className="relative">
        <div className="hidden xl:flex absolute inset-0">
          <div className="flex-1 bg-[#E5E7EB]" />
          <div className="flex-1" />
        </div>
        <div className="mx-auto max-w-7xl xl:flex xl:gap-28">
          <div className="flex-1 bg-[#E5E7EB] z-10">
            <div className="container py-12 space-y-6 ">
              <Link to="/resources" className="text-xl font-medium">
                <span className="pr-1">←</span> Resource Library
              </Link>
              <img
                className="aspect-[3/4] w-full border border-black object-cover"
                src={featuredImage}
                alt=""
              />
            </div>
          </div>
          <div className="flex-1 container py-12 z-10 xl:flex xl:flex-col xl:justify-center">
            <h1 className="text-[32px]">{title}</h1>
            <div className="inline-flex gap-4 items-center flex-wrap">
              <span className="space-x-2">
                <span>Published</span>
                <time dateTime={date} className="font-bold">
                  {dayjs(date).format('MMM D, YYYY')}
                </time>
              </span>
              {category && (
                <span
                  className={classNames(
                    'px-2 py-1 rounded-full border text-xs font-semibold uppercase leading-3 text-center',
                    {
                      'border-cyan-500': category.slug === 'ebooks',
                      'border-orange-400': category.slug === 'customer-stories',
                      'border-fuchsia-900': category.slug === 'white-papers',
                    }
                  )}
                >
                  {category.name}
                </span>
              )}
            </div>
            {resourceFileUrl && (
              <a
                target="_blank"
                href={resourceFileUrl}
                className="btn-primary border w-full my-8 block"
                rel="noreferrer"
              >
                Download Now
              </a>
            )}
            {contentNode}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query GET_RESOURCE($id: String!) {
    resource: wpResource(id: { eq: $id }) {
      title
      excerpt: lwContentTextExcerpt
      resourceFileUrl
      lwContent {
        isGutenberg
        content
      }
      slug
      uri
      date
      modified
      featuredImage {
        node {
          mediaItemUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      categories: resourcesCategories {
        nodes {
          id
          name
          slug
        }
      }
      seo {
        ...postSeoFields
      }
    }
  }
`

export default Resource
